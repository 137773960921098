//https://resultadosdigitais.com.br/blog/exemplos-de-paginas-sobre/
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from "react-ga4";
import './App.css';
import Layout from './components/Layout/Layout';
import Home from './Pages/Home';
import CestasCooperafloraPrivacyPolicy from './components/Policies/cestascooperfloraprivacypolicy';
import ColinhaZaraDonatoPolicy from './components/Policies/colinhazaradonato';

function App() {

  ReactGA.initialize("G-FKZ015E64E");
  ReactGA.send("pageview");

  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/cooperflora' element={<CestasCooperafloraPrivacyPolicy />} />
            <Route path='/colinhazaradonato' element={<ColinhaZaraDonatoPolicy />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
