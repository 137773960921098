import { Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCard/ProjectCard';
import votesMapProjectImage from '../../assets/images/votesmapprojectimage.png';
import studentsMapProjectImage from '../../assets/images/studentsmapprojectimage.png';
import cestasCooperfloraImage from '../../assets/images/cestascooperfloraimage.png';
import votaImage from '../../assets/images/vota.svg';
import territoriosNegrosImage from '../../assets/images/territoriosnegrosimage.jpeg';


import './Projects.css';

const Projects = () => {
  return (
    <div className='Projects'>
      <Row>
        <Col md-12 className='mt-3'>
          <h2 className="text-center">Projetos</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProjectCard
            projectImage={votesMapProjectImage}
            projectTitle='Mapa de Votos'
            projectText="Veja a distribuição de votos dos candidatos e candidatas a vereador(a) de Campinas das eleições de 2021."
            projectLink='https://mapadevotos2020.bordignon.pro.br/'
          />
        </Col>
        <Col>
          <ProjectCard
            projectImage={studentsMapProjectImage}
            projectTitle='Distribuição geográfica dos alunos do IF Campinas'
            projectText="Distribuição geográfica dos estudantes do Instituto Federal de São Paulo - Campus Campinas."
            projectLink='https://ifspcmp.bordignon.pro.br/'
          />
        </Col>
        <Col>
          <ProjectCard
            projectImage={cestasCooperfloraImage}
            projectTitle='Cestas Cooperflora Barão'
            projectText="Aplicação para celular de gestão das vendas da Cooperativa Cooperflora do grupo de consumo de Barão Geraldo."
            projectLink='https://play.google.com/store/apps/details?id=br.pro.bordignon'
          />
        </Col>
        <Col>
          <ProjectCard
            projectImage={territoriosNegrosImage}
            projectTitle='Territórios Negros'
            projectText="Territórios Negros é uma publicação de iniciativa do mandato da vereadora Guida Calixto (PT)."
            projectLink='https://territorioss.web.app/'
          />
        </Col>
      </Row>
      <Row>
        <Col md-12 className='mt-3'>
          <h2 className="text-center">Projetos não ativos</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProjectCard
            projectImage={votaImage}
            projectTitle='Vota 2018'
            projectText="Site que ajudava os eleitores e eleitoras a escolherem o seu candidato(a) a deputado estadual ou federal em 2018 através de um match de respostas."
            projectLink='https://vota.org.br/'
          />
        </Col>
      </Row>
    </div>
  )
}

export default Projects;