import { Row, Col } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Banner from '../components/Banner/Banner';
import SideBox from '../components/SideBox/SideBox';
import Projects from '../components/Projects/Projects';
const Home = () => {
  console.log('Cheguei na home');
  return (
    <section className='Home'>
      <section className='Content'>
        <Banner />
        <Row>
          <Col md='9'>
            <Projects />
          </Col>
          <Col md='3'>
            <SideBox />
          </Col>
        </Row>
      </section>
    </section >
  )
}

export default Home;