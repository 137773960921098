import { Row } from 'react-bootstrap';
import FacebookIcon from '../icons/facebookIcon';
import InstagramIcon from '../icons/instagramIcon';
import TelegramIcon from '../icons/telegramIcon';
import EmailIcon from '../icons/emailIcon';
import LinkedinIcon from '../icons/linkedinIcon';
import './Footer.css'

const Footer = () => {
    return (
        <Row className='Footer'>
            <span className='Icon'>
                <a href='https://www.facebook.com/andre.l.bordignon'><FacebookIcon /></a>
            </span>
            <span className='Icon'>
                <a href='mailto:albordignon@gmail.com'><EmailIcon className='Icon' /></a>
            </span>
            <span className='Icon'>
                <a href=''><TelegramIcon className='Icon' /></a>
            </span>
            <span className='Icon'>
                <a href='https://www.linkedin.com/in/andr%C3%A9-bordignon-5334744/'><LinkedinIcon className='Icon' /></a>
            </span>
            <span className='Icon'>
                <a href='https://www.instagram.com/albordignon/'><InstagramIcon className='Icon' /></a>
            </span>
        </Row>
    )
}

export default Footer;