import { Card } from 'react-bootstrap';
import fotoAndre from '../../assets/images/fotoprincipal.png';
import './Bio.css';


const Bio = ({ children }) => {
  return (
    <Card className='Bio'>
      <Card.Img className='CardImage' variant="top" src={fotoAndre} />
      <Card.Body>
        <Card.Title className='CardTitle text-center'>André Luís Bordignon</Card.Title>
        <Card.Text className='text-justify'>
          André Luís Bordignon é professor do Instituto Federal de São Paulo, campus Campinas, ministrando disciplinas da área de computação. É um dos fundadores da organização Minha Campinas, uma rede de pessoas conectadas na construção de um processo mais participativo das tomadas de decisão de interesse público da cidade utilizando tecnologia social e digital. É voluntário na Casa Hacker. Defensor das tecnologias abertas e livres. Tem mestrado em Engenharia da Computação pela Universidade Estadual de Campinas
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Bio;