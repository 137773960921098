import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Banner.css';

const Banner = props => {
  return (
    <Container fluid className='Banner'>
      <Row>
        <Col md='12' className='text-center'>
          <p className='subtitle'>"Se a educação sozinha não transforma a sociedade, sem ela tampouco a sociedade muda."</p>
          <p className='text-right'>Paulo Freire</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;