import Footer from '../Footer/Footer';
import './Layout.css';

const Layout = ({ children }) => {
  return (
    <section className='Layout'>
      {/* <Header /> */}
      {children}
      <Footer />
    </section >
  )
}

export default Layout;