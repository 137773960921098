import { Card } from 'react-bootstrap';
import './ProjectCard.css';

const ProjectCard = ({ projectImage, projectTitle, projectText, projectLink }) => {
  return (
    <section className='ProjectCard'>
      <Card style={{ width: '18rem' }}>
        <Card.Img className='CardImage'variant="top" src={projectImage} />
        <Card.Body>
          <Card.Title>{projectTitle}</Card.Title>
          <Card.Text>
            {projectText}
          </Card.Text>
          <a className="Button1 active" href={projectLink} aria-current="true">
            Link para o projeto
          </a>
        </Card.Body>
      </Card>
    </section>
  )
}

export default ProjectCard;