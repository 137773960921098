import { Container } from 'react-bootstrap';
import Bio from '../Bio/Bio';
import './SideBox.css';

const SideBox = ({ children }) => {
  return (
    <Container className='SideBox'>
      <p>Links</p>
      <a className='Link' href='http://www.minhacampinas.org.br'><p>Minha Campinas</p></a>
      <a className='Link' href='http://www.ifsp.edu.br'><p>Instituto Federal de São Paulo</p></a>

      <Bio />
    </Container>
  )
}

export default SideBox;